import React, { Component } from "react";
import styled from "styled-components";
// import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../AppContext";
import Can from "./Can";

import {
  // Badge,
  Avatar as MuiAvatar,
  Grid,
  Hidden,
  // InputBase,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import {
  AccountCircle as AccountCircleIcon,
  ExitToApp as ExitToAppIcon,
  AccountBox as AccountBoxIcon,
  Brightness4 as Brightness4Icon,
  AccessAlarms as AccessAlarmsIcon,
  Person as UserIcon,
} from "@material-ui/icons";

// import {
// Bell,
// MessageSquare,
// Search as SearchIcon,
// Power,
// User as UserIcon,
// } from "react-feather";

// import { Counter as AlarmCounter } from "./alarms/counter";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Avatar = styled(MuiAvatar)`
  margin-right: ${(props) => props.theme.spacing(6)}px;
  width: ${(props) => props.theme.spacing(8)}px;
  height: ${(props) => props.theme.spacing(8)}px;
`;

// const Indicator = styled(Badge)`
//   .MuiBadge-badge {
//     background: ${(props) => props.theme.header.indicator.background};
//     color: ${(props) => props.theme.palette.common.white};
//   }
//   .MuiBadge-badge:empty {
//     display: none;
//   }
// `;

// const Search = styled.div`
//   border-radius: 2px;
//   background-color: ${props => props.theme.header.background};
//   display: none;
//   position: relative;
//   width: 100%;

//   &:hover {
//     background-color: ${props => darken(0.05, props.theme.header.background)};
//   }

//   ${props => props.theme.breakpoints.up("md")} {
//     display: block;
//   }
// `;

// const SearchIconWrapper = styled.div`
//   width: 50px;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// const Input = styled(InputBase)`
//   color: inherit;
//   width: 100%;

//   > input {
//     color: ${props => props.theme.header.search.color};
//     padding-top: ${props => props.theme.spacing(2.5)}px;
//     padding-right: ${props => props.theme.spacing(2.5)}px;
//     padding-bottom: ${props => props.theme.spacing(2.5)}px;
//     padding-left: ${props => props.theme.spacing(12)}px;
//     width: 160px;
//   }
// `;

// const Flag = styled.img`
//   border-radius: 50%;
//   width: 22px;
//   height: 22px;
// `;

// class LanguageMenu extends Component {
//   state = {
//     anchorMenu: null
//   };

//   toggleMenu = event => {
//     this.setState({ anchorMenu: event.currentTarget });
//   };

//   closeMenu = () => {
//     this.setState({ anchorMenu: null });
//   };

//   render() {
//     const { anchorMenu } = this.state;
//     const open = Boolean(anchorMenu);

//     return (
//       <React.Fragment>
//         <IconButton
//           aria-owns={open ? "menu-appbar" : undefined}
//           aria-haspopup="true"
//           onClick={this.toggleMenu}
//           color="inherit"
//         >
//           <Flag src="/static/img/flags/us.png" alt="English" />
//         </IconButton>
//         <Menu
//           id="menu-appbar"
//           anchorEl={anchorMenu}
//           open={open}
//           onClose={this.closeMenu}
//         >
//           <MenuItem
//             onClick={() => {
//               this.closeMenu();
//             }}
//           >
//             English
//           </MenuItem>
//           <MenuItem
//             onClick={() => {
//               this.closeMenu();
//             }}
//           >
//             French
//           </MenuItem>
//           <MenuItem
//             onClick={() => {
//               this.closeMenu();
//             }}
//           >
//             German
//           </MenuItem>
//           <MenuItem
//             onClick={() => {
//               this.closeMenu();
//             }}
//           >
//             Dutch
//           </MenuItem>
//         </Menu>
//       </React.Fragment>
//     );
//   }
// }

class UserMenu extends Component {
  state = {
    anchorMenu: null,
  };

  toggleMenu = (event) => {
    this.setState({ anchorMenu: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorMenu: null });
  };

  render() {
    const { anchorMenu } = this.state;
    const open = Boolean(anchorMenu);

    return (
      <AppContext.Consumer>
        {(app) => (
          <React.Fragment>
            <Tooltip title="User menu">
              <IconButton
                aria-owns={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.toggleMenu}
                color="inherit"
              >
                <UserIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorMenu}
              open={open}
              onClose={this.closeMenu}
            >
              <MenuItem
                onClick={() => {
                  this.closeMenu();
                }}
                // component="div"
                // to={process.env.PUBLIC_URL + "/profile"}
              >
                <Avatar>
                  <AccountCircleIcon />
                </Avatar>
                <ListItemText primary="Signed in as" secondary={app.user.id} />
              </MenuItem>

              <MenuItem
                onClick={() => {
                  this.closeMenu();
                }}
                component={Link}
                to={process.env.PUBLIC_URL + "/profile"}
              >
                <ListItemIcon>
                  <AccountBoxIcon fontSize="small" />
                </ListItemIcon>
                Profile
              </MenuItem>
              {app?.toggleTheme && (
                <Can I="view" a="themeToggler">
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      app.toggleTheme();
                      this.closeMenu();
                    }}
                  >
                    <ListItemIcon>
                      <Brightness4Icon fontSize="small" />
                    </ListItemIcon>
                    Dark theme: {app?.state?.theme === 1 ? "On" : "Off"}
                  </MenuItem>
                </Can>
              )}

              <MenuItem
                onClick={() => {
                  this.closeMenu();
                }}
                component={Link}
                to={process.env.PUBLIC_URL + "/auth/sign-out"}
              >
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                Sign out
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </AppContext.Consumer>
    );
  }
}

const Header = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppContext.Consumer>
      {(app) => (
        <AppBar position="sticky" elevation={0}>
          <Toolbar>
            <Grid container alignItems="center">
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={onDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
              {/* <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder="Search topics" />
            </Search>
          </Grid> */}
              <Grid item xs />
              <Grid item>
                <Tooltip title={`Alerts assigned to ${app?.user?.id}`}>
                  <IconButton
                    color="inherit"
                    component={Link}
                    to="/alerts/AssignedToMe"
                  >
                    <AccessAlarmsIcon />
                  </IconButton>
                </Tooltip>

                {/* <IconButton color="inherit">
              <Indicator badgeContent={3}>
                <MessageSquare />
              </Indicator>
            </IconButton> */}
                {/* <Can I="view" a="alarmUserIndicator">
                  <IconButton color="inherit">
                    <Indicator
                      badgeContent={
                        <AlarmCounter
                          filter={`alarm.user='${app.user.id}'%20AND%20status%20IN%20(3,4)`}
                        />
                      }
                    >
                      <AccessAlarmsIcon />
                    </Indicator>
                  </IconButton>
                </Can> */}
                {/* <LanguageMenu /> */}

                <UserMenu />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
    </AppContext.Consumer>
  </React.Fragment>
);

export default Header;

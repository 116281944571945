import Connection from "./Connection.js";

export const Auth = {
  currentUser: null,
  // _signupRoute: "com.auth.signup",

  // setSignupRoute(newRoute) {
  //   this._signupRoute = newRoute;
  // },

  _onOpened(args) {
    var session = args[0];
    var details = args[1];
    this.currentUser = {
      session_id: session.id,
      id: details.authid,
      role: details.authrole,
      provider: details.authprovider,
      method: details.authmethod,
      extra: details.authextra || {},
    };
    return Promise.resolve([session, this.currentUser]);
  },

  _onClosed() {
    this.currentUser = null;
    return Promise.reject(...arguments).catch((error) => {
      throw error;
    });
  },

  signUp(userPayload) {
    let session = Connection._currentConnection.session;
    return session.call(this._signupRoute, [userPayload]);
  },

  logIn(credentials) {
    if (!credentials.username || !credentials.password) {
      throw new Error("One of credentials can't be null!");
    }
    let authextra = credentials.authextra || {
      accept_authrole: ["frontend"],
    };
    let connect = Connection.reconnectWithAuth(
      credentials.username,
      credentials.password,
      authextra
    )
      .then(this._onOpened.bind(this))
      .catch(this._onClosed.bind(this));
    return connect;
  },

  logOut() {
    if (this.isLogged()) {
      Connection.close("wamp.close.logout");
      this.currentUser = null;
    }
  },

  isLogged() {
    return this.currentUser !== null;
  },

 
  become(authid, token) {
    return Connection.reconnectWithToken(authid, token)
      .then(this._onOpened.bind(this))
      .catch(this._onClosed.bind(this));
  },

};

// import Autobahn from 'autobahn';
let Autobahn = global.autobahn;
let msgpackSerializer = new Autobahn.serializer.MsgpackSerializer();

let Connection = {
  _url: null,
  _realm: null,
  _unreachableHandlers: [],
  _lostHandlers: [],
  _errorHandlers: [],
  _readyHandlers: [],
  _completedHandlers: [],
  _currentConnection: null,
  _defaultConnectParam: {
    max_retries: -1,
    max_retry_delay: 12,
    serializers: [msgpackSerializer],
  },

  setConnectParam(param) {
    this._defaultConnectParam = param;
  },

  isConnected() {
    return this._currentConnection && this._currentConnection.isConnected;
  },

  isOpen() {
    return this._currentConnection && this._currentConnection.isOpen;
  },

  connect() {
    var promise = new Promise((resolve, reject) => {
      this._currentConnection.onopen = function (session, details) {
        resolve([session, details]);
        for (var i = 0; i < this._readyHandlers.length; i++) {
          this._readyHandlers[i]([session, details]);
        }
      }.bind(this);

      this._currentConnection.onclose = (reason, details) => {
        // console.log('onclose', reason);
        var i;
        switch (reason) {
          case "unreachable":
            console.log("Server unreachable", details);
            for (i = 0; i < this._unreachableHandlers.length; i++) {
              this._unreachableHandlers[i](details);
            }
            reject(details);
            break;
          case "lost":
            console.log("Connection", reason, details);
            if (details.reason === "wamp.close.normal") {
              for (i = 0; i < this._completedHandlers.length; i++) {
                this._completedHandlers[i]([reason, details]);
              }
            } else {
              for (i = 0; i < this._lostHandlers.length; i++) {
                this._lostHandlers[i](details);
              }
            }
            reject(details);
            break;
          default:
            console.log("Connection", reason, details);
            for (i = 0; i < this._errorHandlers.length; i++) {
              this._errorHandlers[i]([reason, details]);
            }
            reject([reason, details]);
        }
      };

      this._currentConnection.open();
    });

    promise.catch((error) => console.log("WAMP Connection", error));

    return promise;
  },

  onUnreachable(callback) {
    this._unreachableHandlers.push(callback);
    return this;
  },

  onLost(callback) {
    this._lostHandlers.push(callback);
    return this;
  },

  onReady(callback) {
    this._readyHandlers.push(callback);
    return this;
  },

  /*
   * onCompleted: a callback function executed when the connection is closed without error.
   */
  onCompleted(callback) {
    this._completedHandlers.push(callback);
    return this;
  },

  onError(callback) {
    this._errorHandlers.push(callback);
    return this;
  },

  makeConnection(params) {
    if (this._currentConnection && this._currentConnection.isOpen) {
      this._currentConnection.close();
    }

    const curParams = Object.assign(this._defaultConnectParam, params);
    this._currentConnection = new Autobahn.Connection(curParams);
  },

  close(reason) {
    if (this._currentConnection && this._currentConnection.isOpen) {
      this._currentConnection.close(reason);
    }
  },

  initialize(url, realm) {
    this._url = url;
    this._realm = realm;
    return this;
  },

  reconnectAnonymously() {
    this.makeConnection({
      url: this._url,
      realm: this._realm,
      authmethods: ["anonymous"],
    });
    return this.connect();
  },

  reconnectWithCookie() {
    this.makeConnection({
      url: this._url,
      realm: this._realm,
      authmethods: ["cookie"],
    });
    return this.connect();
  },

  reconnectWithToken(authid, token) {
    function onchallenge(session, method, extra) {
      if (method !== "ticket") {
        throw new Error(`Unknown authentication method: ${method} ?!`);
      }

      return token;
    }

    this.makeConnection({
      url: this._url,
      realm: this._realm,
      authmethods: ["ticket"],
      authid,
      onchallenge,
    });

    return this.connect();
  },

  reconnectWithAuth(authid, secret, authextra) {
    function onchallenge(session, method, extra) {
      if (method !== "wampcra") {
        throw new Error(`Unknown authentication method: ${method} ?!`);
      }
      if ("salt" in extra) {
        secret = Autobahn.auth_cra.derive_key(
          secret,
          extra.salt,
          extra.iterations,
          extra.keylen
        );
      }
      return Autobahn.auth_cra.sign(secret, extra.challenge);
    }

    this.makeConnection({
      url: this._url,
      authextra: authextra,
      realm: this._realm,
      authmethods: ["wampcra"],
      authid,
      onchallenge,
    });

    return this.connect();
  },
};

export default Connection;

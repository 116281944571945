/* eslint-disable no-template-curly-in-string */
import React from "react";

import async from "../components/Async";

import {
  Users as UsersIcon,
  Settings as SettingsIcon,
  Sliders as SlidersIcon,
} from "react-feather";

//import EcoIcon from "@material-ui/icons/Eco";
import AlertIcon from "@material-ui/icons/Alarm";
import BusinessIcon from "@material-ui/icons/Business";
import RouterIcon from "@material-ui/icons/Router";

// Static imported pages
import SignOut from "../pages/auth/SignOut";
import { Counter as AlertCounter } from "../components/alerts/counter";
// Ekatra components

const Tankboard = async(() => import("../pages/tankboard/Tankboard"));
const Customer = async(() => import("../pages/customer/Customer"));
const Location = async(() => import("../pages/location/LocationPassport"));
const TankPassport = async(() => import("../pages/tank/TankPassport"));
const TankMnemo = async(() => import("../pages/tank/TankMnemo"));
const TankAudit = async(() => import("../pages/tank/TankAudit"));
const SmartSensor = async(() => import("../pages/smart_sensor/SmartSensor"));

//const Fertilizer = async(() => import("../pages/fertilizer/Fertilizer"));
// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));

const Log = async(() => import("../pages/logging/Log"));
const Users = async(() => import("../pages/user/User"));
const Members = async(() => import("../pages/member/Member"));
const UserCard = async(() => import("../pages/user/UserCard"));
const MemberEdit = async(() => import("../pages/member/MemberEdit"));

const Profile = async(() => import("../pages/user/Profile"));
const Session = async(() => import("../pages/session/Session"));
const AlertsPage = async(() => import("../pages/alert/AlertsPage"));
const AuditPage = async(() => import("../pages/audit/Audit"));
const RtdbListPage = async(() => import("../pages/rtdb/ArrayList"));
const ServicesListPage = async(() => import("../pages/services/ServicesList"));
const MailerRule = async(() => import("../pages/mailerrule/MailerRule"));

// const SignUp = async(() => import("../pages/auth/SignUp"));
// const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
// const Page404 = async(() => import("../pages/auth/Page404"));
// const Page500 = async(() => import("../pages/auth/Page500"));
// const SignOut = async(() => import("../pages/auth/SignOut"));
// const Docs = async(() => import("../pages/docs/Documentation"));

// const dashboardsRoutes = {
//   // header: "Dashboard",
//   id: "Dashboard",
//   name: "Dashboard",
//   path: "/",
//   component: Dashboard,
//   icon: <Briefcase />
// };

const tankboardRoutes = {
  // header: "Dashboard",
  id: "Dashboard",
  path: "/",
  component: Tankboard,
};

const alertListRoutes = {
  id: "Alerts",
  path: "/alerts/:env",
  component: AlertsPage,
};

const auditListRoutes = {
  id: "Audit",
  path: "/manage/audit",
  component: AuditPage,
};

const rtdbListRoutes = {
  id: "RtdbList",
  path: "/manage/rtdb/:dbName?",
  component: RtdbListPage,
};

const servicesListRoutes = {
  id: "Services",
  path: "/manage/service/:containerName?/:serviceName?",
  title: "System Service ${ containerName }/${ serviceName }",
  component: ServicesListPage,
};

const mailerRuleRoutes = {
  id: "MailerRule",
  path: "/manage/mailerrules/:mrid?",
  component: MailerRule,
};

const customerRoutes = {
  id: "Customer",
  path: "/customer/:cid?",
  component: Customer,
};

const locationRoutes = {
  id: "Location",
  path: "/location/:lid",
  component: Location,
};

const tankRoutes = {
  id: "Tank",
  path: "/tank/:tid",
  component: TankPassport,
};

const tankMnemoRoutes = {
  id: "Tank Mnemo",
  path: "/tank-mnemo/:tid",
  component: TankMnemo,
};

const tankAuditRoutes = {
  id: "Tank Audit",
  path: "/tank-audit/:tid",
  component: TankAudit,
};

const smartsensorRoutes = {
  id: "SmartSensor",
  path: "/smart-sensor/:cid?",
  component: SmartSensor,
};

// HIDDEN due to TS-20
// const fertilizerRoutes = {
//   id: "Fertilizer",
//   path: "/fertilizer/:fid?",
//   component: Fertilizer,
//   icon: <EcoIcon />
// };

const userCardRoutes = {
  id: "Users",
  title: "User ${ id }",
  path: "/manage/users/:id",
  component: UserCard,
  icon: <UsersIcon />,
};

const usersRoutes = {
  id: "Users",
  path: "/manage/users",
  name: "Users",
  component: Users,
};

const membersEditRoutes = {
  id: "Members",
  path: "/manage/members/:id",
  title: "Member ${ id }",
  component: MemberEdit,
};

const membersRoutes = {
  id: "Members",
  path: "/manage/members",
  name: "Members",
  component: Members,
};

const sessionsRoutes = {
  id: "Sessions",
  path: "/manage/session/:action?",
  name: "Sessions",
  component: Session,
};
const logsRoutes = {
  id: "Logs",
  path: "/manage/log/:containerName?/:serviceName?",
  name: "System Logs",
  title: "System Logs ${ containerName }/${ serviceName }",
  component: Log,
};

// const manageUserRoutes = {
//   id: "Users",
//   path: "/users/:action?/:id?",
//   component: User,
//   icon: <UsersIcon />,
// };

const profileRoutes = {
  id: "Profile",
  path: "/profile",
  component: Profile,
};

const signOutRoutes = {
  id: "SignOut",
  path: "/auth/sign-out",
  component: SignOut,
};

const singInRoutes = {
  id: "SignIn",
  path: "/auth/sign-in",
  component: SignIn,
};

const resetPasswordRoutes = {
  id: "ResetPassword",
  path: "/auth/reset-password/:authid?/:ticket?",
  component: ResetPassword,
};

export const sideMenu = [
  {
    id: "Dashboard",
    path: "/",
    name: "Dashboard",
    icon: <SlidersIcon />,
  },
  {
    id: "Operational Alerts",
    path: "/alerts/Operational",
    icon: <AlertIcon />,
    badge: <AlertCounter filter="Operational" />,
  },
  {
    id: "Equipment Alerts",
    path: "/alerts/Equipment",
    icon: <AlertIcon />,
    badge: <AlertCounter filter="Equipment" />,
  },
  {
    id: "Assigned Alerts",
    path: "/alerts/Assigned",
    icon: <AlertIcon />,
    badge: <AlertCounter filter="Assigned" />,
  },
  {
    id: "Customer",
    name: "Customers",
    path: "/customer",
    icon: <BusinessIcon />,
  },
  {
    id: "SmartSensor",
    name: "Smart Sensors",
    path: "/smart-sensor",
    icon: <RouterIcon />,
  },
  {
    id: "Manage",
    name: "Manage",
    path: "/manage",
    icon: <SettingsIcon />,
    children: [
      {
        id: "Audit",
        path: "/manage/audit",
        name: "Audit",
      },
      {
        id: "Members",
        path: "/manage/members",
        name: "Members",
      },
      {
        id: "MailerRule",
        path: "/manage/mailerrules",
        name: "Notification Rules",
      },
      {
        id: "RTDB",
        path: "/manage/rtdb",
        name: "RTDB",
      },
      {
        id: "Sessions",
        path: "/manage/session",
        name: "Sessions",
      },
      {
        id: "Logs",
        path: "/manage/log",
        name: "System Logs",
      },
      {
        id: "Services",
        path: "/manage/service",
        name: "System Services",
      },
      {
        id: "Users",
        path: "/manage/users",
        name: "Users",
      },
    ],
  },
];

/*
 *  Used in Router DashboardLayout
 */
export const dashboard = [
  alertListRoutes,
  auditListRoutes,
  rtdbListRoutes,
  tankboardRoutes,
  customerRoutes,
  locationRoutes,
  tankRoutes,
  tankMnemoRoutes,
  tankAuditRoutes,
  smartsensorRoutes,
  mailerRuleRoutes,
  //fertilizerRoutes,
  usersRoutes,
  membersRoutes,
  membersEditRoutes,
  sessionsRoutes,
  servicesListRoutes,
  logsRoutes,
  // manageUserRoutes,

  userCardRoutes,
  profileRoutes,
];

/*
 *  Used in Router no authentication required
 */
export const pub = [singInRoutes, resetPasswordRoutes];

/*
 *  Used in Router DashboardLayout
 */
export const signOut = [signOutRoutes];

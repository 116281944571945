/*
  WAMPLink constructor
    queryRoute - default graphql registered procedure handler
    wampClient - autobahn
   
  query context: {queryRoute: "graphql"}
*/

import { ApolloLink, Observable } from "apollo-link";
import { print } from "graphql/language/printer";

const definitionIsSubscription = (d) => {
  return d.kind === "OperationDefinition" && d.operation === "subscription";
};

export default class WAMPLink extends ApolloLink {
  constructor(queryRoute, wampClient) {
    super();
    this.queryRoute = queryRoute;
    this.client = wampClient;
  }

  defer() {
    return this.client.call(...arguments);
  }

  request(operation /*, forward*/) {
    const context = operation.getContext();
    const queryRoute = context.queryRoute || this.queryRoute;
    const isSubscription = operation.query.definitions.some(
      definitionIsSubscription
    );

    return new Observable((observer) => {
      const { operationName, extensions, variables, query } = operation;
      const _query = print(query);
      let handler;
      // console.log("<<<", _query, "variables", variables);
      if (isSubscription) {
        handler = this.defer(
          queryRoute,
          [_query],
          { operationName, extensions, variables },
          { receive_progress: true }
        );

        handler.then(
          function (response) {
            observer.next(response);
            observer.complete();
          },
          function (networkError) {
            observer.error(networkError);
          },
          function (progress) {
            console.log(">>>progress", progress);
            observer.next(progress);
          }
        );
      } else {
        handler = this.defer(
          queryRoute,
          [_query],
          { operationName, extensions, variables },
          { receive_progress: false }
        );
        handler.then(
          function (response) {
            console.log(">>>response", response, variables /*, _query */);
            observer.next(response);
            observer.complete();
          },
          function (networkError) {
            observer.error(networkError);
          }
        );
      }

      return () => {
        if (isSubscription && handler?.cancel) {
          handler.cancel({ mode: "kill" });
        }
      };
    });
  }
}

import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";

import WAMPLink from "./modules/apollo-link-wamp";
import AutobahnClient from "./modules/autobahn";

const errorLink = onError(
  ({ /*response, */ networkError, graphQLErrors, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          "[GraphQL error]:",
          " Message: ",
          message,
          ", Location:",
          locations,
          ", Path:",
          path,
          "operation:",
          operation
        )
      );
    if (networkError) {
      console.log("[Network error]:", networkError);
    }
    // if (response) {
    //   response.errors = undefined;
    // }
  }
);

const qlClient = new ApolloClient({
  name: "qlClient",
  link: ApolloLink.from([
    errorLink,
    new WAMPLink("ekatra.tlds.graphql", AutobahnClient)
  ]),
  cache: new InMemoryCache({
    addTypename: true
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore"
    },
    query: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all"
    },
    mutate: {
      errorPolicy: "all"
    }
  },
  ssrMode: false,
  // connectToDevTools: process.env.NODE_ENV !== "production"
});

export default qlClient;

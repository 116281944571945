import React from "react";
import styled from "styled-components";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

function PermissionDenied(props) {
  return (
    <Wrapper>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Permission Denied.
      </Typography>

      <Button
        onClick={event => {
          props.history.go(-1);
        }}
        variant="contained"
        color="secondary"
        mt={2}
      >
        Return back
      </Button>
    </Wrapper>
  );
}

export default PermissionDenied;

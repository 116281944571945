import React from "react";

import Loader from "./Loader";

//const sleep = m => new Promise(r => setTimeout(r, m));

export default function asyncComponent(importComponent) {
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props);
      this.mounted = true;

      this.state = {
        component: null
      };
    }


    async componentDidMount() {
      // await sleep(250);

      const { default: component } = await importComponent();
      if (this.mounted) {
          this.setState({
              component: component
          });
      }
    }

    async componentWillUnmount() {
        this.mounted = false;
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : <Loader />;
    }
  }

  return AsyncComponent;
}

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  dashboard as dashboardRoutes,
  pub as publicRoutes,
  signOut as signOutRoutes
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import PrivateRoute from "../components/PrivateRoute";
import PermissionDenied from "../components/PermissionDenied";

const childRoutes = (Layout, loginNeeded, ability, routes) =>
  routes.map(
    ({ id, children, path, title, name, component: Component }, index) =>
      children ? (
        // Route item with children
        children.map(
          ({ id, path, title, name, component: Component }, index) => (
            <PrivateRoute
              key={index}
              path={path}
              loginNeeded={loginNeeded}
              title={title || name || id}
              exact
              component={props => (
                <Layout>
                  {ability.can("view", id) ? (
                    <Component {...props} />
                  ) : (
                    <PermissionDenied {...props} />
                  )}
                </Layout>
              )}
            />
          )
        )
      ) : (
        // Route item without children
        <PrivateRoute
          key={index}
          path={path}
          loginNeeded={loginNeeded}
          title={title || name || id}
          exact
          component={props => (
            <Layout>
              {ability.can("view", id) ? (
                <Component {...props} />
              ) : (
                <PermissionDenied {...props} />
              )}
            </Layout>
          )}
        />
      )
  );

const Routes = React.memo(function({ loginNeeded, ability }) {
  return (
    <Router>
      <Switch>
        {childRoutes(AuthLayout, false, ability, publicRoutes)}
        {childRoutes(DashboardLayout, loginNeeded, ability, dashboardRoutes)}
        {childRoutes(AuthLayout, loginNeeded, ability, signOutRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
});

export default Routes;

import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useSubscribe } from "../../modules/autobahn/useSubscribe";

const subOptions = { get_retained: true };

function Counter({ filter }) {
  const topic = `fsm.tlds.alert.group.count.alert.${filter}`;
  const [state, setState] = useState("");

  const handleSubUpdate = useCallback(
    (data) => {
      setState(data?.length && "" + (data[0] || ""));
    },
    [setState]
  );

  useSubscribe(topic, handleSubUpdate, subOptions);

  return state;
}

export { Counter };

Counter.propTypes = {
  filter: PropTypes.string,
};

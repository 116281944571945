import Connection from "./Connection";
import { Auth } from "./Auth";
import { Broker } from "./Broker";

var Autobahn = {
  Auth,
  Connection,
  Broker: null,
  isConnected: null,
  initialize(url, realm) {
    if (Connection._currentConnection) {
      throw new Error("Autobahn is already initialized!");
    }
    this.Broker = new Broker(Connection, global.autobahn.msgpack());
    return Connection.initialize(url, realm);
  },
  browserInitialize({ hostname, port, path, realm }) {
    hostname = hostname || document.location.hostname;
    port = port || document.location.port;
    let schema = document.location.protocol === "http:" ? "ws:" : "wss:";
    let host = port ? hostname + ":" + port : hostname;
    let wsuri = schema + "//" + host + "/" + path;
    return this.initialize(wsuri, realm);
  },
  isConnectionReady() {
    return (
      Connection._currentConnection && Connection._currentConnection.isConnected
    );
  },
  publish() {
    return Connection._currentConnection.session.publish(...arguments);
  },
  subscribe() {
    return Connection._currentConnection.session.subscribe(...arguments);
  },
  unsubscribe() {
    return (
      Connection._currentConnection &&
      Connection._currentConnection.session &&
      Connection._currentConnection.session.unsubscribe(...arguments)
    );
  },
  call() {
    return Connection._currentConnection.session.call(...arguments);
  },
  register() {
    return Connection._currentConnection.session.register(...arguments);
  }
};

export default Autobahn;
export { default as useSubscribe } from "./useSubscribe";
export { default as useAutobahnState } from "./useAutobahnState";
export { AutobahnContext, AutobahnContextProvider } from "./AutobahnContext";

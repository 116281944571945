import React, { useContext, useEffect } from "react";
import { AutobahnContext } from "../../modules/autobahn";
import { useApolloClient } from "@apollo/react-hooks";
import Loaded from "../../components/Loader";

function SingOut(props) {
  const autobahn = useContext(AutobahnContext);
  const store = useApolloClient();

  useEffect(() => {
    // if (!autobahn.Auth.isLogged()) {
    //     props.history.replace('/');
    // }
    const doLogout = async () => {
      try {
        await autobahn.Auth.logOut();
        store.resetStore();
      } catch (err) {
        console.log("Logout error", err);
      }
    };

    doLogout();
  }, [autobahn.Auth, store]);

  return <Loaded />;
}

export default SingOut;

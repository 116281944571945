import React from "react";

import { Modal } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

function ModalLoader(props) {
  return (
    <Modal
      open={props.open}
      disableEnforceFocus
      disableAutoFocus
      disablePortal
      style={{ outline: 0, zIndex: "9999" }}
    >
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          minHeight: "100%"
        }}
      >
        <CircularProgress m={2} color="secondary" />
      </div>
    </Modal>
  );
}

export default ModalLoader;

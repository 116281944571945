import { blue, green, grey } from "@material-ui/core/colors";

const lightDesaturatedBlueVariant = {
  name: "DesaturatedBlue",
  palette: {
    type: "light",
    primary: {
      main: blue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: blue[500],
      contrastText: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFFFFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFFFFF",
  },
  sidebar: {
    color: grey[200],
    background: "#1B2430",
    action: {
      active: "rgba(0,0,0,0.4)",
      hover: "rgba(0,0,0,0.1)",
      selected: "rgba(0,0,0,0.1)",
    },
    header: {
      color: grey[200],
      background: "#232f3e",
      brand: {
        color: blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#232f3e",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: blue[500],
    },
  },
  body: {
    background: "#f7f7f7",
  },
};

const darkVariant = {
  name: "Dark",
  palette: {
    type: "dark",
    background: {
      paper: "#212121",
      default: "#303030",
    },
    primary: {
      main: blue[400],
      contrastText: "#FFF",
    },
    secondary: {
      main: blue[500],
      contrastText: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#212121",
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#151515",
  },
  sidebar: {
    color: grey[200],
    background: "#212121",
    action: {
      active: "#4c4c4c",
      hover: "#383838",
      selected: "#383838",
    },
    header: {
      color: grey[200],
      background: "#212121",
      brand: {
        color: blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#151515",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: blue[500],
    },
  },
  body: {
    background: "#181818",
  },
};

const variants = [lightDesaturatedBlueVariant, darkVariant];

export default variants;

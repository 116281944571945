import React from "react";
import PropTypes from "prop-types";

export default function useAutobahnState({ client, defineAbilitiesFor }) {
  const [loginNeeded, setLoginNeeded] = React.useState();
  const [connectionReason, setConnectionReason] = React.useState(null);

  React.useEffect(() => {
    client.Connection.onUnreachable((details) => {
      setConnectionReason("The server is unreachable!");
    });

    client.Connection.onLost((details) => {
      setConnectionReason("Connection lost. Trying to Connect");
    });

    client.Connection.onReady((details) => {
      client.Auth._onOpened(details);
      defineAbilitiesFor(client.Auth.currentUser);
      setLoginNeeded(false);
      setConnectionReason(null);
    });

    client.Connection.onCompleted(() => {
      defineAbilitiesFor(null);
      setLoginNeeded(true);
      setConnectionReason(null);
    });

    client.Connection.onError(() => {
      defineAbilitiesFor(null);
      setLoginNeeded(true);
      setConnectionReason(null);
    });
  }, [client, setLoginNeeded, setConnectionReason, defineAbilitiesFor]);

  return { loginNeeded, connectionReason, user: client.Auth.currentUser };
}

export { useAutobahnState };

useAutobahnState.propTypes = {
  client: PropTypes.object.isRequired,
  defineAbilitiesFor: PropTypes.func.isRequired,
};

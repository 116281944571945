import React from "react";
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { rgba } from "polished";
import { Layers } from "react-feather";

import {
  //  Avatar,
  //  Grid,
  Box as MuiBox,
  Collapse,
  Chip,
  ListItem,
  ListItemText,
  Drawer as MuiDrawer,
  Typography
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { sideMenu } from "../routes/index";
import Can from "./Can";
import "../vendor/perfect-scrollbar.css";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled.div`
  background-color: ${props => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

const Brand = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding: 8px 24px 8px 24px;
  justify-content: flex-start;
  text-decoration: none;
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const BrandIcon = styled(Layers)`
  margin-right: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.sidebar.header.brand.color};
`;

const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};

  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background-color: ${props => props.theme.sidebar.action.hover};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => props.theme.sidebar.action.active};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${props => props.theme.spacing(14)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;

  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => props.theme.sidebar.action.active};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
  span.MuiChip-label:empty {
    display: none;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

//const SidebarFooter = styled.div`
//  background-color: ${props =>
//    props.theme.sidebar.footer.background} !important;
//  padding: ${props => props.theme.spacing(3)}px
//    ${props => props.theme.spacing(4)}px;
//  border-right: 1px solid rgba(0, 0, 0, 0.12);
//`;
//
//const SidebarFooterText = styled(Typography)`
//  color: ${props => props.theme.sidebar.footer.color};
//`;
//
//const Dot = styled.span`
//  width: 12px;
//  height: 12px;
//  margin-right: 4px;
//  background-color: ${props => props.theme.sidebar.footer.online.background};
//  display: inline-block;
//  border-radius: 50%;
//  margin-bottom: -0.5px;
//`;

function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
}

function SidebarLink({ name, to, badge }) {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = index => {
    // Collapse all elements
    Object.keys(this.state).forEach(
      item =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false
        }))
    );

    // Toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));
  };

  UNSAFE_componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    sideMenu.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome
      }));
    });
  }

  render() {
    const { classes, staticContext, ...other } = this.props;

    return (
      <Drawer variant="permanent" {...other}>
        <Brand>
          <BrandIcon /> <Box ml={1}>{process.env.REACT_APP_SITE_NAME}</Box>
        </Brand>
        <Scrollbar>
          <List disablePadding>
            <Items>
              {sideMenu.map((category, index) => (
                <Can I="view" a={category.id} key={index}>
                  <React.Fragment>
                    {category.header ? (
                      <SidebarSection>{category.header}</SidebarSection>
                    ) : null}

                    {category.children ? (
                      <React.Fragment key={index}>
                        <SidebarCategory
                          isOpen={!this.state[index]}
                          isCollapsable={true}
                          name={category.name}
                          icon={category.icon}
                          button={true}
                          onClick={() => this.toggle(index)}
                        />

                        <Collapse
                          in={this.state[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {category.children.map((route, index) => (
                            <Can I="view" a={route.id} key={index}>
                              <SidebarLink
                                key={index}
                                name={route.name}
                                to={route.path.split("/:")[0]}
                                icon={route.icon}
                                badge={route.badge}
                              />
                            </Can>
                          ))}
                        </Collapse>
                      </React.Fragment>
                    ) : (
                      <Can I="view" a={category.id}>
                        <SidebarCategory
                          isCollapsable={false}
                          name={category.name || category.id}
                          to={category.path.split("/:")[0]}
                          activeClassName="active"
                          component={NavLink}
                          icon={category.icon}
                          exact
                          badge={category.badge}
                        />
                      </Can>
                    )}
                  </React.Fragment>
                </Can>
              ))}
            </Items>
          </List>
        </Scrollbar>
        {/*        <SidebarFooter>
          <Grid container spacing={2}>
            <Grid item>
              <Avatar
                alt="Lucy Lavender"
                src="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item>
              <SidebarFooterText variant="body2">
                Lucy Lavender
              </SidebarFooterText>
              <SidebarFooterText variant="body2">
                <Dot />
                Online
              </SidebarFooterText>
            </Grid>
          </Grid>
        </SidebarFooter>*/}
      </Drawer>
    );
  }
}

export default withRouter(Sidebar);

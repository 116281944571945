import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";

import ModalLoader from "./components/ModalLoader";
import Routes from "./routes/Routes";
import useLocalStorage from "./modules/useLocalStorage";
import AutobahnClient, {
  useAutobahnState,
  AutobahnContextProvider,
} from "./modules/autobahn";

import { AbilityContext } from "./components/Can";
import ability, { defineAbilitiesFor } from "./ability";

import qlClient from "./qlClient";
import maTheme from "./theme";
import AppContext from "./AppContext";

function onReloadApp() {
  setTimeout(() => {
    global.location.reload(true);
  }, 100);
}

function App() {
  const [theme, setTheme] = useLocalStorage("theme", 0);

  const { loginNeeded, connectionReason, user } = useAutobahnState({
    client: AutobahnClient,
    defineAbilitiesFor,
  });

  React.useEffect(() => {
    AutobahnClient.browserInitialize({
      path: "ws",
      realm: "ekatra",
    }).reconnectWithCookie();

    AutobahnClient.Broker.subscribe("ekatra.reload", onReloadApp, null, {
      get_retained: false,
    });
  }, []);

  const toggleTheme = () => {
    setTheme((p) => (p === 0 ? 1 : 0));
  };

  const matheme = maTheme[theme === 1 ? 1 : 0];

  return (
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={matheme}>
          <ThemeProvider theme={matheme}>
            <AbilityContext.Provider value={ability}>
              <AutobahnContextProvider value={AutobahnClient}>
                <ApolloProvider client={qlClient}>
                  <AppContext.Provider
                    value={{
                      user,
                      state: { theme },
                      // XXX: to activate the theme switching function, rename the variable  _toggleTheme to toggleTheme
                      _toggleTheme: toggleTheme, 
                    }}
                  >
                    <ModalLoader open={!!connectionReason} />
                    <Routes ability={ability} loginNeeded={loginNeeded} />
                  </AppContext.Provider>
                </ApolloProvider>
              </AutobahnContextProvider>
            </AbilityContext.Provider>
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
}
export default App;

import { AbilityBuilder, defineAbility } from "@casl/ability";

/**
 * Defines how to detect object's type:
 * https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 *
 * https://www.npmjs.com/package/@casl/react
 */
// function subjectName(item) {
//   if (!item || typeof item === "string") {
//     return item;
//   }

//   return item.__type;
// }

const ability = defineAbility((can) => {
  can(["view"], "SignIn");
  can(["view"], "ResetPassword");
});

function defineAbilitiesFor(user) {
  const { rules, can /*, cannot */ } = new AbilityBuilder();
  const roles = user?.extra?.roles || [];

  if (roles.includes("dev")) {
    can(["manage"], "all"); // can anything (i.e., view, create, update, delete)
  }

  can(["view"], "SignIn");
  can(["view"], "ResetPassword");
  if (user) {
    can(["manage"], "Profile");
    can(["view"], "SignOut");
  }

  if (roles.includes("user")) {
    can(["view"], "Dashboard");
    can(["view", "edit_content"], "Tank");
    can(["view"], "Tank Audit");

    can(["view", "edit_contact"], "Customer");
    can(["view", "edit_contact"], "Location");
    // can(["view"], "Fertilizer");
    can(["user.ACK", "user.CLOSE"], "AlertsAction");
    can(["view"], "Alerts");
    // can(["view"], "Equipment Alerts");
    // can(["view"], "Operational Alerts");
  }

  if (roles.includes("architect")) {
    can(["view"], "Dashboard");
    can(["manage"], "Tank");
    can(["view"], "Tank Audit");
    // can(["manage"], "Tank Mnemo");
    can(["manage"], "Location");
    can(["manage"], "Customer");
    // can(["view", "manage"], "Fertilizer");
    can(["user.ACK", "user.CLOSE"], "AlertsAction");
    can(["view"], "Alerts");
    can(["view"], "Equipment Alerts");
    can(["view"], "Operational Alerts");
    can(["view"], "Manage");
    can(["manage"], "Members");
  }

  if (roles.includes("admin")) {
    can(["view"], "Dashboard");
    can(["view", "edit", "edit_content", "edit_mode"], "Tank");
    can(["view"], "Tank Audit");
    // can(["view"], "Tank Mnemo");
    can(["view", "edit_contact"], "Customer");
    can(["view", "edit_contact"], "Location");
    // can(["view"], "Fertilizer");
    can(["manage"], "AlertsAction");
    can(["view"], "Alerts");
    can(["view"], "Equipment Alerts");
    can(["view"], "Operational Alerts");
    can(["view"], "Assigned Alerts");

    can(["view"], "Manage");
    can(["manage"], "MailerRule");
    can(["manage"], "Members");
    can(["manage"], "Users");

    can(["manage"], "SmartSensor");
    // can(["view"], "Sessions");
  }

  ability.update(rules);
  return ability;
}

export { defineAbilitiesFor };

export default ability;

import React from "react";
import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Loader() {
  return (
    <Root maxWidth="sm">
       {/* | 'indeterminate' | 'static'; */}
      <CircularProgress m={2} disableShrink color="primary" variant="indeterminate" />
    </Root>
  );
}

export default Loader;

import { useState, useMemo } from "react";
import PropTypes from "prop-types";

// // Usage
// function App() {
//   // Similar to useState but first arg is key to the value in local storage.
//   const [name, setName] = useLocalStorage('name', 'Bob');

//   return (
//     <div>
//       <input
//         type="text"
//         placeholder="Enter your name"
//         value={name}
//         onChange={e => setName(e.target.value)}
//       />
//     </div>
//   );
// }

function getStoredValue(key, initialValue) {
  try {
    const item = global.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    console.log(error);
    return initialValue;
  }
}

// Hook
function useLocalStorage(key, initialValue) {
  const [flag, setFlag] = useState(false);
  const currentResult = useMemo(() => {
    const storedValue = getStoredValue(key, initialValue);
    const setValue = value => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setFlag(!flag);
        const jValueToStore = JSON.stringify(valueToStore);
        if (jValueToStore === JSON.stringify(initialValue)) {
          global.localStorage.removeItem(key);
        } else {
          global.localStorage.setItem(key, jValueToStore);
        }
      } catch (error) {
        console.log(error);
      }
    };
    return [storedValue, setValue];
  }, [key, flag, initialValue]);

  return currentResult;
}

export { useLocalStorage };
export default useLocalStorage;

useLocalStorage.propTypes = {
  key: PropTypes.string.isRequired,
  initialValue: PropTypes.any
};

useLocalStorage.defaultProps = {
  initialValue: undefined
};

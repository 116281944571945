import React from "react";
import PropTypes from "prop-types";

const AutobahnContext = React.createContext();
AutobahnContext.displayName = "AutobahnContext";

const AutobahnContextProvider = ({ children, value }) => {
  const isConnected = value?.isConnectionReady();
  const client = React.useMemo(() => ({ ...value, isConnected }), [
    isConnected,
    value
  ]);
  return (
    <AutobahnContext.Provider value={client}>
      {children}
    </AutobahnContext.Provider>
  );
};

export { AutobahnContextProvider, AutobahnContext };

AutobahnContextProvider.propTypes = {
  value: PropTypes.object.isRequired
};

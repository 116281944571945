import React from "react";
import { Redirect, Route } from "react-router-dom";
import Loader from "./Loader";
import { template } from "lodash";

const PrivateRoute = ({
  component: Component,
  loginNeeded,
  title,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      let compiled = template(title);
      global.document.title = compiled(props.match.params);
      return loginNeeded === false ? (
        <Component {...props} />
      ) : loginNeeded === true ? (
        <Redirect to={"/auth/sign-in" + props.location.hash} />
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute"
          }}
        >
          <Loader />
        </div>
      );
    }}
  />
);

export default PrivateRoute;
